import React from 'react';
import Login from './views/Login'
import './index.css'

function App() {
  return (
    <>
      <Login />
    </>
  )
}

export default App